import { actionCreatorFactory, Action, Success } from 'typescript-fsa'
import { Shareholder, ProfileResponse } from 'typescript-fetch-api'

const actionCreator = actionCreatorFactory('Accounts')

export type FetchShareholdingAccountsAction = Action<void>
export const fetchShareholdingAccounts = actionCreator.async<void, Shareholder[], Error>('FETCH_SHAREHOLDING_ACCOUNTS')

export const accountSelected = actionCreator<number>('ACCOUNT_SELECTED')

export type FetchProfileAction = Action<void>
export type FetchProfileSuccessAction = Action<Success<void, ProfileResponse>>
export const fetchProfile = actionCreator.async<void, ProfileResponse, Error>('FETCH_PROFILE')
