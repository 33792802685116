import actionCreatorFactory from 'typescript-fsa'

const actionCreator = actionCreatorFactory('NAVIGATION')

export const navigateToContactUs = actionCreator('NAVIGATE_TO_CONTACT_US')

export const navigateToLogin = actionCreator('NAVIGATE_TO_LOGIN')

export const navigateToForgotPassword = actionCreator('NAVIGATE_TO_FORGOT_PASSWORD')

export const navigateToResetPassword = actionCreator('NAVIGATE_TO_RESET_PASSWORD')
