import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { Shareholder } from 'typescript-fetch-api'

import * as actions from './actions'
import { callApi } from '../api/functions'
import { getShareholdingApi } from '../api'
import { downloadFile } from '../../utils/functions'
import { accountSelector } from '../accounts/selectors'

function* handleFetchAnnualStatements(action: actions.FetchAnnualStatementsAction): SagaIterator {
	// get current shareholder
	const shareholder: Shareholder | undefined = yield select(accountSelector)
	if (!shareholder) {
		yield put(actions.fetchAnnualStatements.failed({ params: action.payload, error: new Error('No account selected') }))
	} else {
		yield call(
			// @ts-ignore callApi
			callApi,
			action,
			actions.fetchAnnualStatements,
			(payload: actions.FetchAnnualStatementsPayload) => {
				const shareholderNumber = Number(shareholder.allotmentNumber)
				return getShareholdingApi().getAnnualStatements({ shareholderNumber })
			}
		)
	}
}

function* handleDownloadAnnualStatement(action: actions.DownloadAnnualStatementAction): SagaIterator {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		actions.downloadAnnualStatement,
		(payload: actions.DownloadAnnualStatementPayload) => {
			return getShareholdingApi()
				.downloadAnnualStatement(payload)
				.then((response: Blob) => {
					const mimeType = 'application/pdf'
					// Create a Blob from the PDF Stream
					const file = new Blob([response], { type: mimeType })
					// chrome sometimes blocks large files being opened in new window, couldnt find exact size limit so using 2mb
					if (file.size < 2000000) {
						// Build a URL from the file
						const fileURL = URL.createObjectURL(file)
						// Open the URL on new Window
						window.open(fileURL, '_blank')
					} else {
						// create a download
						const filename = `Annual Statement ${payload.shareholderNumber} ${payload.financialYear}.pdf`
						downloadFile(response, filename)
					}
				})
		}
	)
}

function* handleDownloadAnnualStatements(action: actions.DownloadAnnualStatementsAction): SagaIterator {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		actions.downloadAnnualStatements,
		(payload: actions.DownloadAnnualStatementsPayload) => {
			return getShareholdingApi()
				.downloadAnnualStatements({ downloadAnnualStatementsRequest: payload })
				.then((response: Blob) => {
					// save zip
					const filename = `Annual Statements ${payload.shareholderNumber}.zip`
					downloadFile(response, filename)
				})
		}
	)
}

function* handleEmailAnnualStatements(action: actions.EmailAnnualStatementsAction): SagaIterator {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		actions.emailAnnualStatements,
		(payload: actions.EmailAnnualStatementsPayload) => {
			return getShareholdingApi().emailAnnualStatements({ emailAnnualStatementsRequest: payload })
		}
	)
}

export default function* (): SagaIterator {
	yield takeLatest(actions.fetchAnnualStatements.started, handleFetchAnnualStatements)
	yield takeEvery(actions.downloadAnnualStatement.started, handleDownloadAnnualStatement)
	yield takeEvery(actions.downloadAnnualStatements.started, handleDownloadAnnualStatements)
	yield takeEvery(actions.emailAnnualStatements.started, handleEmailAnnualStatements)
}