import { call, put, takeEvery } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'

import { callApi } from '../api/functions'
import { authStoreRehydrated, login } from '../auth/actions'
import { fetchShareholdingAccounts, FetchShareholdingAccountsAction, fetchProfile, FetchProfileAction } from './actions'
import { getUserApi } from '../api'

function* handleLoginDone(): SagaIterator {
	yield put(fetchShareholdingAccounts.started())
	yield put(fetchProfile.started())
}

function* handleFetchShareholdingAccounts(action: FetchShareholdingAccountsAction): SagaIterator {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		fetchShareholdingAccounts,
		() => {
			return getUserApi().getShareholders()
		}
	)
}

function* handleFetchProfile(action: FetchProfileAction): SagaIterator {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		fetchProfile,
		() => {
			return getUserApi().getAccounts()
		}
	)
}

export default function* (): SagaIterator {
	yield takeEvery(login.done, handleLoginDone)
	yield takeEvery(authStoreRehydrated, handleLoginDone)
	yield takeEvery(fetchShareholdingAccounts.started, handleFetchShareholdingAccounts)
	yield takeEvery(fetchProfile.started, handleFetchProfile)
}