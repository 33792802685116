import { SagaIterator } from 'redux-saga'
import { call, takeLatest } from 'redux-saga/effects'
import { ContactUsShareholdingRequest } from 'typescript-fetch-api'

import * as actions from './actions'
import { callApi } from '../api/functions'
import { getUserApi } from '../api'

function* handleSendContactUs(action: actions.SendContactUsAction): SagaIterator {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		actions.sendContactUs,
		(payload: actions.ContactUsPayload) => {
			const contactUsShareholdingRequest: ContactUsShareholdingRequest = {
				fullname: payload.fullname,
				email: payload.email,
				phone: payload.mobile,
				accountNumber: payload.accountNumber,
				accountName: payload.accountName,
				message: payload.message,
			}
			return getUserApi().contactShareholding({ contactUsShareholdingRequest, pwRec: payload.pwRec })
		}
	)
}


export default function* (): SagaIterator {
	yield takeLatest(actions.sendContactUs.started, handleSendContactUs)
}