import { actionCreatorFactory, Action, Success } from 'typescript-fsa'
import { OAuthResponse } from 'typescript-fetch-api'

const actionCreator = actionCreatorFactory('Auth')

export interface LoginRequestParams {
    username?: string
    password?: string
    refreshToken?: string
}
export type LoginRequestAction = Action<LoginRequestParams>
export type LoginRequestSuccessAction = Action<Success<LoginRequestParams, OAuthResponse>>
export const login = actionCreator.async<LoginRequestParams, OAuthResponse, Error>('LOGIN')

export const refreshedAuthToken = actionCreator<OAuthResponse>('REFRESHED_AUTH_TOKEN')

/** Signals that refreshing failed. The payload is the time that it failed. */
export const refreshAuthTokenFailed = actionCreator('REFRESH_AUTH_TOKEN_FAILED')

export const logout = actionCreator('LOGOUT')

export const authStoreRehydrated = actionCreator('AUTH_STORE_REHYDRATED')

interface CheckExchangeTokenRequestParams {
    token: string
}
export type CheckExchangeTokenAction = Action<CheckExchangeTokenRequestParams>
export const checkExchangeToken = actionCreator<CheckExchangeTokenRequestParams>('CHECK_EXCHANGE_TOKEN')

export interface ForgotPasswordPayload {
    loginId: string
}
export type ForgotPasswordAction = Action<ForgotPasswordPayload>
export type ForgotPasswordSuccessAction = Action<Success<ForgotPasswordPayload, Response>>
export const forgotPassword = actionCreator.async<ForgotPasswordPayload, Response, Error>('FORGOT_PASSWORD')

export interface ChangePasswordRequestPayload {
    mobileNumber?: string
    password: string
    code: string
}
export type ChangePasswordRequestAction = Action<ChangePasswordRequestPayload>
export const changePassword = actionCreator.async<ChangePasswordRequestPayload, Response, Error>('CHANGE_PASSWORD')

export interface RequestShareholdingAccessPayload {
    loginId: string
    shareholderNumber: number
}
export type RequestShareholdingAccessAction = Action<RequestShareholdingAccessPayload>
export const requestShareholdingAccess = actionCreator.async<RequestShareholdingAccessPayload, void, Error>('REQUEST_SHAREHOLDING_ACCESS')