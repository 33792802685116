import images from './imagesProvider'

export const getEnvironmentIcon = (environment: 'LOCAL' | 'TEST' | 'DEMO' | string): string | undefined => {
	switch (environment) {
		case 'LOCAL':
			return images.icon_app_local
		case 'TEST':
			return images.icon_app_test
		case 'DEMO':
			return images.icon_app_demo
		default:
			return undefined
	}
}