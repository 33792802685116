import { GradedCashYearStatement } from 'typescript-fetch-api'
import { actionCreatorFactory, Action } from 'typescript-fsa'

const actionCreator = actionCreatorFactory('GradedCashStatements')

export interface FetchGradedCashStatementsPayload {
	//
}
export type FetchGradedCashStatementsAction = Action<FetchGradedCashStatementsPayload>
export const fetchGradedCashStatements = actionCreator.async<FetchGradedCashStatementsPayload, GradedCashYearStatement[], Error>('FETCH_GRADED_CASH_STATEMENTS')

export interface DownloadGradedCashStatementPayload {
	financialYear: number
}
export type DownloadGradedCashStatementAction = Action<DownloadGradedCashStatementPayload>
export const downloadGradedCashStatement = actionCreator.async<DownloadGradedCashStatementPayload, Blob, Error>('DOWNLOAD_GRADED_CASH_STATEMENT')
