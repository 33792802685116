import { SagaIterator } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'

import * as navigationActions from './actions'
import * as homeActions from '../home/actions'
import * as RootNavigation from '../navigation/NavigationManager'
import { forgotPassword, ForgotPasswordSuccessAction } from '../auth/actions'
import { isText } from '../../utils/functions'
import { ForgotPasswordLocationState } from './types'

function handleNavigateToTile(action: homeActions.NavigateToTileAction) {
	RootNavigation.navigateToTile(action.payload.path)
}

function handleNavigateToHome() {
	RootNavigation.navigateToHome()
}

function handleNavigateToContactUs() {
	RootNavigation.navigateToContactUs()
}

function handleNavigateToLogin() {
	RootNavigation.navigateToLogin()
}

function handleNavigateToForgotPassword() {
	RootNavigation.navigateToForgotPassword()
}

function handleNavigateToResetPassword() {
	RootNavigation.navigateToResetPassword()
}

function handleNavigateToVerifyCode(action: ForgotPasswordSuccessAction) {
	const isMobileNumber = action.payload.params.loginId && !isText(action.payload.params.loginId)

	// we only navigate to verify code for PHONE as with email the user will be sent a url link to follow.
	if (isMobileNumber) {
		const state: ForgotPasswordLocationState = { mobileNumber: action.payload.params.loginId }
		RootNavigation.navigateToResetPassword(state)
	}
}

export default function* (): SagaIterator {
	yield takeEvery(homeActions.navigateToTile, handleNavigateToTile)
	yield takeEvery(homeActions.navigateToHome, handleNavigateToHome)
	yield takeEvery(navigationActions.navigateToContactUs, handleNavigateToContactUs)
	yield takeEvery(navigationActions.navigateToLogin, handleNavigateToLogin)
	yield takeEvery(navigationActions.navigateToForgotPassword, handleNavigateToForgotPassword)
	yield takeEvery(navigationActions.navigateToResetPassword, handleNavigateToResetPassword)
	yield takeEvery(forgotPassword.done, handleNavigateToVerifyCode)
}
