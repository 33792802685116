import { Action, AsyncActionCreators } from 'typescript-fsa'
import { SagaIterator } from 'redux-saga'
import { call, put } from 'redux-saga/effects'
import { HTTPHeaders, OAuthResponse, SignInGrantTypeEnum, SignInRequest } from 'typescript-fetch-api'

import { store } from '../root/index'
import { LoginRequestParams, refreshAuthTokenFailed, refreshedAuthToken } from '../auth/actions'
import { getOAuthApi, CLIENT_ID } from './index'

/** 
 * A generic function for calling API while calling typescript-fsa async action's done and failed actions.
 */
export function* callApi<A, B>(
    action: Action<A | undefined>,
    async: AsyncActionCreators<unknown, unknown, Error>,
    func: (payload: unknown, options: RequestInit) => Promise<B | undefined>,
    retry: boolean = true
): SagaIterator {
    try {
        const result = yield call(() => {
            return func(action.payload, {})
        })

        yield put(async.done({ params: action.payload, result }))
    } catch (response) {
        if (response instanceof Response) {
            if (response.status === 401) {
                if (retry) {
                    try {
                        const refreshResult = yield call(refreshTokenSafely)
                        if (refreshResult) {
                            // @ts-ignore callApi
                            yield call(callApi, action, async, func, false)
                            return
                        }
                    } catch (e) {
                        // catching error thrown by refreshTokenSafely if get another 401 due to invalid refresh token value
                        console.log('Refreshing access token failed, failing API call', action.type)
                    }
                }
            }
            const error = new Error(response.statusText)
            error.name = 'APIError'
            yield put(async.failed({ params: action.payload, error: error }))
        } else if (response instanceof Error) {
            yield put(async.failed({ params: action.payload, error: response }))
        } else {
            yield put(async.failed({ params: action.payload, error: new Error('Unknown API response') }))
        }
    }
}

let refreshingToken: Promise<OAuthResponse> | undefined
/**
 * This function was created to fix the issue where both offlineApi and callApi 401 errors were fetching a new access token at same time.
 * This meant that after the second one came back, the first one would already be invalid, and so the next request that tried to use it would fail.
 * This function fixes this by keeping a reference to inflight refreshToken requests and returning the existing request if one is available.
 */
export function refreshTokenSafely(): Promise<OAuthResponse> {
    // if already refreshing token, return the same promise
    if (refreshingToken) {
        return refreshingToken
    }
    refreshingToken = refreshTokenAndApply()
    // clear promise reference after it completes
    refreshingToken
        .then(() => {
            refreshingToken = undefined
        })
        .catch(() => {
            refreshingToken = undefined
        })
    return refreshingToken
}

/** 
 * Refresh the access token, apply it to the store, and return a promise
 * indicating whether or not it was successful. This methods gets the current
 * refresh token from the store, so there's no need to know any context to
 * call it.
 */
export function refreshTokenAndApply(): Promise<OAuthResponse> {
    return new Promise((resolve, reject) => {
        const authToken = store.getState().auth.token
        if (authToken) {
            authenticate({
                refreshToken: authToken.refresh_token
            }).then(result => {
                console.log('refreshTokenAndApply success', result)
                store.dispatch(refreshedAuthToken(result))
                resolve(result)
            }).catch(error => {
                console.log('refreshTokenAndApply error', error)
                store.dispatch(refreshAuthTokenFailed())
                reject(error)
            })
        } else {
            reject(new Error('Not logged in'))
        }
    })
}

export function authenticate(request: LoginRequestParams): Promise<OAuthResponse> {
    const grantType = request.refreshToken ? SignInGrantTypeEnum.REFRESH_TOKEN : SignInGrantTypeEnum.PASSWORD
    // Do not go through callApi because we don't want to retry the signIn request if we get a 401 (due to invalid refresh token)
    const requestParameters: SignInRequest = {
        grantType,
        clientId: CLIENT_ID,
        username: request.username,
        password: request.password,
        refreshToken: request.refreshToken,
    }
    const headers: HTTPHeaders = { 'Content-Type': 'application/x-www-form-urlencoded' }
    return getOAuthApi(headers).signIn(requestParameters)
}