import { actionCreatorFactory, Action } from 'typescript-fsa'

const actionCreator = actionCreatorFactory('ContactUs')

export interface ContactUsPayload {
	fullname: string
	email?: string
	mobile?: string
	accountNumber: string
	accountName: string
	message: string
	pwRec: string // captcha code
}
export type SendContactUsAction = Action<ContactUsPayload>
export const sendContactUs = actionCreator.async<ContactUsPayload, void, Error>('SEND_CONTACT_US_MESSAGE')
