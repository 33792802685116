import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Shareholder } from 'typescript-fetch-api'

import { fetchShareholdingAccounts, fetchProfile, accountSelected } from './actions'
import { emailAnnualStatements } from '../annual/actions'
import { logout } from '../auth/actions'

export interface StoreState {
	readonly selectedAccountId: number | undefined
	readonly accounts: Shareholder[]
	readonly fetchingAccounts: boolean
	readonly errorFetchingAccounts?: Error

	// profile
	readonly userId?: number
	readonly firstname?: string
	readonly lastname?: string
	readonly email?: string
	readonly mobile?: string
}

export const INITIAL_STATE: StoreState = {
	selectedAccountId: undefined,
	accounts: [],
	fetchingAccounts: false,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	// fetch accounts
	.case(fetchShareholdingAccounts.started, (state): StoreState => ({
		...state, accounts: [], fetchingAccounts: true, errorFetchingAccounts: undefined,
	}))
	.case(fetchShareholdingAccounts.done, (state, payload): StoreState => ({
		...state, fetchingAccounts: false, accounts: payload.result,
		// if has no selected account and user has some accounts, set default to the first account
		selectedAccountId: !state.selectedAccountId && payload.result.length > 0 ? payload.result[0].id : state.selectedAccountId,
	}))
	.case(fetchShareholdingAccounts.failed, (state, payload): StoreState => ({
		...state, fetchingAccounts: false, errorFetchingAccounts: payload.error,
	}))

	// account selected
	.case(accountSelected, (state, payload): StoreState => ({
		...state, selectedAccountId: payload
	}))

	// fetch profile
	.case(fetchProfile.started, (state): StoreState => ({
		...state, userId: undefined, firstname: undefined, lastname: undefined, email: undefined, mobile: undefined,
	}))
	.case(fetchProfile.done, (state, payload): StoreState => ({
		...state, userId: payload.result.userId, firstname: payload.result.firstname, lastname: payload.result.lastname, email: payload.result.email, mobile: payload.result.mobile,
	}))

	// email statements - if user chose to save email for next time, update the cached shareholder details
	.case(emailAnnualStatements.done, (state, payload): StoreState => ({
		...state,
		accounts: payload.params.saveEmail ?
			state.accounts.map(account => {
				// find the matching account and update email address
				if (account.id === state.selectedAccountId)
					return { ...account, email: payload.params.email }
				return account
			}) : state.accounts,
	}))
	// clear state on logout
	.case(logout, (): StoreState => INITIAL_STATE)
