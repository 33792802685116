import { AnnualStatement } from 'typescript-fetch-api'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { fetchAnnualStatements, emailAnnualStatements, downloadAnnualStatement, downloadAnnualStatements, clearDownloadError } from './actions'
import { logout } from '../auth/actions'

export interface StoreState {
	readonly statements: AnnualStatement[]
	readonly loading: boolean
	readonly error?: Error

	readonly sendingEmail: boolean
	readonly errorSendingEmail?: Error

	readonly downloading: boolean
	readonly errorDownloading?: Error
}

export const INITIAL_STATE: StoreState = {
	statements: [],
	loading: false,
	error: undefined,

	sendingEmail: false,
	errorSendingEmail: undefined,

	downloading: false,
	errorDownloading: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	// fetch statements
	.case(fetchAnnualStatements.started, (state): StoreState => ({
		...state, loading: true, error: undefined,
	}))
	.case(fetchAnnualStatements.done, (state, payload): StoreState => ({
		...state, loading: false, statements: payload.result,
	}))
	.case(fetchAnnualStatements.failed, (state, payload): StoreState => ({
		...state, loading: false, error: payload.error,
	}))

	// email statements
	.case(emailAnnualStatements.started, (state): StoreState => ({
		...state, sendingEmail: true, errorSendingEmail: undefined,
	}))
	.case(emailAnnualStatements.done, (state): StoreState => ({
		...state, sendingEmail: false,
	}))
	.case(emailAnnualStatements.failed, (state, payload): StoreState => ({
		...state, sendingEmail: false, errorSendingEmail: payload.error,
	}))

	// download statements
	.cases([downloadAnnualStatement.started, downloadAnnualStatements.started], (state): StoreState => ({
		...state, downloading: true, errorDownloading: undefined,
	}))
	.cases([downloadAnnualStatement.done, downloadAnnualStatements.done], (state): StoreState => ({
		...state, downloading: false,
	}))
	.cases([downloadAnnualStatement.failed, downloadAnnualStatements.failed], (state, payload): StoreState => ({
		...state, downloading: false, errorDownloading: payload.error,
	}))
	.case(clearDownloadError, (state): StoreState => ({
		...state, downloading: false, errorDownloading: undefined,
	}))

	// clear state on logout
	.case(logout, (): StoreState => INITIAL_STATE)
