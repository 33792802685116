import { NavigateFunction, Location } from 'react-router-dom'

import { ForgotPasswordLocationState, Paths } from './types'

/**
 * NavigateFunction must be aquired from the useNavigation hook, so is set via NavigateSetter dummy component
 */
let navigate: NavigateFunction

export function setNavigateFunction(func: NavigateFunction) {
	navigate = func
}

let location: Location
export function setLocation(loc: Location) {
	/* eslint-disable */
	location = loc
}

/* -------------------------------------------------------------------------- */
/*                              Screen Navigation                             */
/* -------------------------------------------------------------------------- */

export const navigateToHome = (replace?: boolean): void => {
	navigate(Paths.HOME, { replace })
}

export const navigateToContactUs = (): void => {
	navigate(Paths.CONTACT_US)
}

export const navigateToLogin = (): void => {
	navigate(Paths.LOGIN)
}

export const navigateToForgotPassword = (): void => {
	navigate(Paths.FORGOT_PASSWORD)
}

export const navigateToResetPassword = (params?: ForgotPasswordLocationState): void => {
	navigate(Paths.RESET_PASSWORD, { state: params })
}

export const navigateToTile = (tilePath: string): void => {
	navigate(tilePath)
}