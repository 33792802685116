import { createStore, combineReducers, compose, applyMiddleware, Middleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer, PersistConfig } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import loggerMiddleware from '../../utils/logger'
import rootSaga from './sagas'

/* Import reducers from our modules */
import * as auth from '../auth/reducer'
import * as accounts from '../accounts/reducer'
import * as annual from '../annual/reducer'
import * as gradedcash from '../gradedcash/reducer'
import * as contactus from '../contactus/reducer'

/**
 * The module store state. Include sub-states for all of the modules / ducks.
 * All of these should be annotated `readonly`, as should everything down
 * the tree of StoreState interfaces, and their contents.
 */
export interface RootStoreState {
	readonly auth: auth.StoreState
	readonly accounts: accounts.StoreState
	readonly annual: annual.StoreState
	readonly gradedcash: gradedcash.StoreState
	readonly contactus: contactus.StoreState
}

/**
 * The root reducer, combines reducers for all of the modules / ducks.
 */
const reducer = combineReducers<RootStoreState>({
	// @ts-ignore PersistPartial _persist to be made optional (https://github.com/rt2zz/redux-persist/issues/1169)
	auth: persistReducer(auth.persistConfig, auth.reducer),
	accounts: accounts.reducer,
	annual: annual.reducer,
	gradedcash: gradedcash.reducer,
	contactus: contactus.reducer,
})

const persistConfig: PersistConfig<RootStoreState> = {
	key: 'root',
	storage, // the storage adapter
	whitelist: [],
}

/**
 * Combines reducers for all of the modules / ducks alongside the persistence configuration.
 */
const persistedReducer = persistReducer<RootStoreState>(persistConfig, reducer)

/**
 * Create the redux-saga middleware - which run constantly and listens for redux actions
 */
const sagaMiddleware = createSagaMiddleware()
const middlewares: Middleware[] = [sagaMiddleware, loggerMiddleware]

/**
 * Enhancers for the store.
 */
const enhancers = compose(
	/* Add the middlewares */
	applyMiddleware(...middlewares),
)

/**
 * Create the store. We do not include an initial state, as each of the module / duck
 * reducers includes its own initial state.
 */
// @ts-ignore - TODO: Check type error
export const store = createStore(persistedReducer, enhancers)

/**
 * Creates a persistor for a given store.
 */
export const persistor = persistStore(store)

/* Run the root saga */
sagaMiddleware.run(rootSaga)
