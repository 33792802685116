import { Theme, createTheme } from '@material-ui/core'
import colors from '../common-ui/utils/colorProvider'

const customColors = {
	lightBlue: '#007597',
	darkBlue: '#002C45',
	highlightBlue: '#00AAC0',
	whiteSmoke: '#F2F2F2',
	nero: '#292929',
}

const theme: Theme = {
	// @ts-ignore
	palette: {
		primary: {
			main: customColors.lightBlue,
			dark: customColors.highlightBlue,
			light: colors.clearDay,
			contrastText: colors.white,
		},
		secondary: {
			main: colors.starDust,
			dark: colors.jumbo,
			light: colors.silver,
			contrastText: colors.black,
		},
		error: {
			main: colors.alizarin,
			dark: colors.fireEngineRed,
			light: colors.azalea,
			contrastText: colors.white,
		},
	},
	// @ts-ignore
	typography: {
		fontFamily: '"Montserrat", sans-serif',
	},
	// @ts-ignore
	breakpoints: {
		values: {
			xs: 0,
			sm: 480,
			md: 768,
			lg: 992,
			xl: 1200,
		},
	},
	overrides: {
		MuiPaper: {
			rounded: {
				borderRadius: 10,
			},
		},
		MuiTableCell: {
			head: {
				borderBottom: `2px solid ${customColors.darkBlue}`,
				color: customColors.lightBlue,
				fontFamily: '"Mr-Eaves-XL-Mod-OT-Ultra", "Arial", sans-serif',
				fontSize: 24,
				backgroundColor: colors.white,
				padding: '8px 16px',
			},
			body: {
				fontFamily: '"Mr-Eaves-XL-Mod-OT-Ultra", "Arial", sans-serif',
				fontSize: 30,
				borderBottom: 'none',
				padding: '8px 16px',
			},
		},
		MuiTableRow: {
			root: {
				'&:nth-child(odd)': {
					backgroundColor: customColors.whiteSmoke,
				},
				'&:nth-child(even)': {
					backgroundColor: colors.white,
				},
			}
		},
		MuiButton: {
			root: {
				minHeight: 70,
				borderRadius: 35,
			},
			label: {
				fontFamily: '"Mr-Eaves-XL-Mod-OT-Heavy", "Arial", sans-serif',
				fontSize: 33,
				lineHeight: '40px',
				letterSpacing: '2px',
				textTransform: 'uppercase',
			},
			outlined: {
				borderColor: colors.black,
				backgroundColor: colors.white,
				borderWidth: 2,
				'&:hover': {
					backgroundColor: colors.black,
					color: colors.white,
					'@media (hover: none)': {
						backgroundColor: colors.black,
						color: colors.white,
					},
				},
			},
			containedPrimary: {
				'&:hover': {
					backgroundColor: colors.white,
					color: customColors.lightBlue,
					'@media (hover: none)': {
						backgroundColor: colors.white,
						color: customColors.lightBlue,
					},
				},
			},
		},
		MuiDialogActions: {
			root: {
				justifyContent: 'center',
				padding: '20px 40px 20px',
				flexDirection: 'column',
				rowGap: '10px',
			},
		},
		MuiDialogTitle: {
			root: {
				textAlign: 'center',
				'& h2': {
					fontSize: 30,
					lineHeight: '36px',
					fontFamily: '"Mr-Eaves-XL-Mod-OT-Heavy", "Arial", sans-serif',
				}
			},
		},
		MuiDialogContent: {
			root: {
				width: '-webkit-fill-available',
				padding: '0px 24px 16px',
			}
		},
		MuiDialogContentText: {
			root: {
				fontSize: 20,
				lineHeight: '24px',
				fontFamily: '"Mr-Eaves-XL-Mod-OT-Reg", "Arial", sans-serif',
				textAlign: 'center',
			},
		},
		MuiFilledInput: {
			root: {
				backgroundColor: colors.white,
				borderTopLeftRadius: 7,
				borderTopRightRadius: 7,
				borderBottomLeftRadius: 7,
				borderBottomRightRadius: 7,
				'&:hover': {
					backgroundColor: colors.white,
					'@media (hover: none)': {
						backgroundColor: colors.white,
					},
				},
				'&$focused': {
					backgroundColor: colors.white,
				},
			},
		},
		MuiInput: {
			input: {
				fontSize: 26,
				lineHeight: '31px',
				fontFamily: '"Mr-Eaves-XL-Mod-OT-Bold", "Arial", sans-serif',
				color: customColors.nero,
				'&$disabled': {
					color: colors.darkGrey,
				},
			},
		},
		MuiInputLabel: {
			root: {
				color: colors.nightRider,
				fontFamily: '"Mr-Eaves-XL-Mod-OT-Bold", "Arial", sans-serif',
				fontSize: 18,
				lineHeight: '22px',
				textAlign: 'left',
			}
		},
	}
}

const muiTheme: Theme = createTheme(theme)
export default muiTheme