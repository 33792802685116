import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { OAuthResponse } from 'typescript-fetch-api'
import storage from 'redux-persist/lib/storage'

import { login, refreshedAuthToken, refreshAuthTokenFailed, forgotPassword, changePassword, requestShareholdingAccess, logout } from '../auth/actions'

export interface StoreState {
	readonly token?: OAuthResponse;
	readonly loggingIn: boolean;
	readonly error?: Error;
	readonly isAdminUser: boolean;
	readonly email?: string; // ldap only
	readonly mobile?: string; // ldap only
	readonly submittingForgottenPassword: boolean;
	readonly errorSubmittingForgottenPassword?: Error;
	readonly submittingChangePassword: boolean;
	readonly errorSubmittingChangePassword?: Error;
	readonly cameFromPwGo: boolean;
	readonly requestingAccess: boolean;
	readonly errorRequestingAccess?: Error;
}

export const INITIAL_STATE: StoreState = {
	token: undefined,
	loggingIn: false,
	isAdminUser: false,
	submittingForgottenPassword: false,
	errorSubmittingForgottenPassword: undefined,
	submittingChangePassword: false,
	errorSubmittingChangePassword: undefined,
	cameFromPwGo: false,
	requestingAccess: false,
}

export const persistConfig = {
	key: 'auth',
	storage,
	whitelist: ['token', 'isAdminUser', 'cameFromPwGo']
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	// clear token when starting login request?
	.case(login.started, (state) => ({
		...state, token: undefined, loggingIn: true, error: undefined, isAdminUser: false, email: undefined, mobile: undefined,
	}))
	// save new access token
	.case(login.done, (state, payload) => ({
		...state,
		token: payload.result,
		loggingIn: false,
		isAdminUser: payload.result.admin ? payload.result.admin : false,
		email: payload.result.email,
		mobile: payload.result.mobile,
		cameFromPwGo: payload.params.username === undefined && payload.params.password === undefined,
	}))
	// save error
	.case(login.failed, (state, payload) => ({
		...state, error: payload.error, loggingIn: false, isAdminUser: false
	}))
	.case(refreshedAuthToken, (state, token): StoreState => ({
		...state, token
	}))
	.case(refreshAuthTokenFailed, (state): StoreState => ({
		/* When the refresh token fails we blank the accessToken, so the app knows we need to re-auth, but we do not
		   do the loggedOut action, so we retain our username property, so we know we need to re-auth as that user
		   in order to preserve our offline queue.
		 */
		...state, token: undefined
	}))
	// forgot password
	.case(forgotPassword.started, (state): StoreState => {
		return { ...state, submittingForgottenPassword: true, errorSubmittingForgottenPassword: undefined }
	})
	.case(forgotPassword.done, (state): StoreState => {
		return { ...state, submittingForgottenPassword: false }
	})
	.case(forgotPassword.failed, (state, { error }): StoreState => {
		return { ...state, submittingForgottenPassword: false, errorSubmittingForgottenPassword: error }
	})
	// change password
	.case(changePassword.started, (state): StoreState => {
		return { ...state, submittingChangePassword: true, errorSubmittingChangePassword: undefined }
	})
	.case(changePassword.done, (state): StoreState => {
		return { ...state, submittingChangePassword: false }
	})
	.case(changePassword.failed, (state, { error }): StoreState => {
		return { ...state, submittingChangePassword: false, errorSubmittingChangePassword: error }
	})
	.case(requestShareholdingAccess.started, (state): StoreState => {
		return { ...state, requestingAccess: true, errorRequestingAccess: undefined }
	})
	.case(requestShareholdingAccess.done, (state): StoreState => {
		return { ...state, requestingAccess: false }
	})
	.case(requestShareholdingAccess.failed, (state, payload): StoreState => {
		return { ...state, requestingAccess: false, errorRequestingAccess: payload.error }
	})
	// clear state on logout
	.case(logout, (): StoreState => INITIAL_STATE)
