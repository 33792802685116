import { Fab } from '@material-ui/core'
import { useSelector } from 'react-redux'

import { getPublicSiteURL } from '../../../utils/envConfig'
import imagesProvider from '../../../utils/imagesProvider'
import { RootStoreState } from '../../root'

const FAB = (): JSX.Element | null => {

	const cameFromPwGo = useSelector<RootStoreState, boolean>(state => state.auth.cameFromPwGo)
	const accountId = useSelector<RootStoreState, number | undefined>(state => state.accounts.selectedAccountId)

	const onFabClick = () => {
		window.open(getPublicSiteURL(), '_blank')
	}

	// the account id will be empty on public contact us and login pages - where we don't show FAB
	if (cameFromPwGo && accountId) {
		return (
			<Fab variant="extended" color="secondary" className="app__fab" onClick={onFabClick}>
				<img src={imagesProvider.pw_app_logo} className="logo" alt="Logo" />
				Return to pwGO
			</Fab>
		)
	}

	return null
}

export default FAB