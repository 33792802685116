import * as firebase from 'firebase/app'

import { FirebaseConfig, getEnvironment } from './envConfig'

export enum SortDirection {
	ASCENDING = 'asc',
	DESCENDING = 'desc',
}

/**
 * Retrieves the comparator given a sort order. Handles empty values.
 * Note: Empty values are sorted last
 * https://stackoverflow.com/a/29829361
 * 
 * @param a the first value to compare
 * @param b the second value to compare
 * @param order the order to sort against (ASCENDING / DESCENDING)
 * @param key the key to sort against
 * @returns the comparator
 */
export const comparator = <T>(a: T, b: T, order: SortDirection, key: keyof T): number => {
	// equal items are sorted equally
	if (a[key] === b[key]) {
		return 0
	}
	// nulls/undefined sort after anything else (dont use !a[key] to handle numbers being 0, eg sort by ordinal)
	else if (a[key] === undefined || a[key] === null) {
		return 1
	}
	else if (b[key] === undefined || b[key] === null) {
		return -1
	}
	// if ascending, lowest sorts first
	else if (order === SortDirection.ASCENDING) {
		return a[key] < b[key] ? -1 : 1
	}
	// if descending, highest sorts first
	return a[key] < b[key] ? 1 : -1
}

/**
 * Handles sorting lists given an order and key
 * 
 * @param data the data
 * @param order the order to sort against (ASCENDING / DESCENDING)
 * @param key the key to sort against
 * @returns the sorted data
 */
export const getSortedData = <T>(data: T[], key: keyof T, order?: SortDirection): T[] => {
	return data.sort((a: T, b: T) => comparator(a, b, order || SortDirection.ASCENDING, key))
}

export function isValidEmail(email: string): boolean {
	if (isInvalidString(email)) {
		return false
	}
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

/**
 * Checks if a string only contains whitespaces (ie. spaces, tabs or line breaks).
 * @param value the string to check
 */
export const isInvalidString = (value: string): boolean => value.replace(/\s/g, '').length === 0

export function isText(text: string): boolean {
	return isNaN(Number(text))
}

/**
 * Handles downloading a file without a url for web.
 * @param file file to download
 * @param filename the name of the file
 */
export const downloadFile = (file: Blob, filename?: string) => {
	const a = document.createElement('a')
	const blobURL = URL.createObjectURL(file)
	if (filename) {
		a.download = filename
	}
	a.href = blobURL
	document.body.appendChild(a)
	a.click()
	document.body.removeChild(a)
}

export const getFirebaseConfig = () => FirebaseConfig[getEnvironment()]

export const setupFirebase = (): { analytics: firebase.analytics.Analytics } => {
	// firebase setup
	const firebaseConfig = getFirebaseConfig()
	const app = firebase.initializeApp(firebaseConfig)

	// analytics setup
	const analytics = firebase.analytics(app)

	return {
		analytics,
	}
}
