import icon_search from '../assets/images/search-icon.png'
import icon_add_chip from '../assets/images/add-chip-icon.png'
import icon_down_arrow from '../assets/images/down-arrow-icon.png'
import icon_no_image from '../assets/images/no-image-icon.png'
import icon_app_local from '../assets/images/app-local-icon.png'
import icon_app_test from '../assets/images/app-test-icon.png'
import icon_app_demo from '../assets/images/app-demo-icon.png'

export default {
	icon_search,
	icon_add_chip,
	icon_down_arrow,
	icon_no_image,
	icon_app_local,
	icon_app_test,
	icon_app_demo,
}