
export enum Environment {
	LOCAL = 'LOCAL',
	DEV = 'DEV',
	TEST = 'TEST',
	DEMO = 'DEMO',
	PRODUCTION = 'PRODUCTION',
}

type UserCredentials = { username: string, password: string }
interface EnvConfig {
	appServerRootURL: string
	publicSiteURL: string
	user: UserCredentials
}

const EnvConfigPwgo: Record<Environment, EnvConfig> = {
	[Environment.LOCAL]: {
		appServerRootURL: 'http://localhost:8080',
		publicSiteURL: 'http://localhost:3000',
		user: { username: '', password: '' },
	},
	[Environment.DEV]: {
		appServerRootURL: 'http://api-dev.pwgo.co.nz',
		publicSiteURL: 'http://web-dev.pwgo.co.nz',
		user: { username: '', password: '' },
	},
	[Environment.TEST]: {
		appServerRootURL: 'https://api-test.pwgo.co.nz',
		publicSiteURL: 'https://web-test.pwgo.co.nz',
		user: { username: '', password: '' },
	},
	[Environment.DEMO]: {
		appServerRootURL: 'https://api-demo.pwgo.co.nz',
		publicSiteURL: 'https://web-demo.pwgo.co.nz',
		user: { username: '', password: '' },
	},
	[Environment.PRODUCTION]: {
		appServerRootURL: 'https://api.pwgo.co.nz',
		publicSiteURL: 'https://pwgo.co.nz',
		user: { username: '', password: '' },
	}
}

export const FirebaseConfig = {
	[Environment.LOCAL]: {
		apiKey: 'AIzaSyDmyp6_hqkozGKWeIuWpH-ORIe9kPr3jKM',
		authDomain: 'plumbing-world.firebaseapp.com',
		databaseURL: 'https://plumbing-world.firebaseio.com',
		projectId: 'plumbing-world',
		storageBucket: 'plumbing-world.appspot.com',
		messagingSenderId: '598089183755',
		appId: '1:598089183755:web:dcbd04ee7c8187035b4d0e',
		measurementId: 'G-1WZS3T4WRH'
	},
	[Environment.DEV]: {
		apiKey: 'AIzaSyDmyp6_hqkozGKWeIuWpH-ORIe9kPr3jKM',
		authDomain: 'plumbing-world.firebaseapp.com',
		databaseURL: 'https://plumbing-world.firebaseio.com',
		projectId: 'plumbing-world',
		storageBucket: 'plumbing-world.appspot.com',
		messagingSenderId: '598089183755',
		appId: '1:598089183755:web:9951cae965589c7c5b4d0e',
		measurementId: 'G-T6DTT0JVSR'
	},
	[Environment.TEST]: {
		apiKey: 'AIzaSyDmyp6_hqkozGKWeIuWpH-ORIe9kPr3jKM',
		authDomain: 'plumbing-world.firebaseapp.com',
		databaseURL: 'https://plumbing-world.firebaseio.com',
		projectId: 'plumbing-world',
		storageBucket: 'plumbing-world.appspot.com',
		messagingSenderId: '598089183755',
		appId: '1:598089183755:web:826b5a38b4c60d545b4d0e',
		measurementId: 'G-SN2VSRQGBN'
	},
	[Environment.DEMO]: {
		apiKey: 'AIzaSyDmyp6_hqkozGKWeIuWpH-ORIe9kPr3jKM',
		authDomain: 'plumbing-world.firebaseapp.com',
		databaseURL: 'https://plumbing-world.firebaseio.com',
		projectId: 'plumbing-world',
		storageBucket: 'plumbing-world.appspot.com',
		messagingSenderId: '598089183755',
		appId: '1:598089183755:web:944402d5aba5c8475b4d0e',
		measurementId: 'G-2LH3XLJ3C4'
	},
	[Environment.PRODUCTION]: {
		apiKey: 'AIzaSyDmyp6_hqkozGKWeIuWpH-ORIe9kPr3jKM',
		authDomain: 'plumbing-world.firebaseapp.com',
		databaseURL: 'https://plumbing-world.firebaseio.com',
		projectId: 'plumbing-world',
		storageBucket: 'plumbing-world.appspot.com',
		messagingSenderId: '598089183755',
		appId: '1:598089183755:web:4ee069b557a36fc45b4d0e',
		measurementId: 'G-MZRQDLBK80'
	},
}

export function getEnvironment(): Environment {
	// read server environment from environment variable
	const serverEnvironment = process.env.REACT_APP_SERVER_ENVIRONMENT
	if (serverEnvironment) {
		if (serverEnvironment === Environment.LOCAL || serverEnvironment === Environment.DEMO || serverEnvironment === Environment.PRODUCTION || serverEnvironment === Environment.TEST || serverEnvironment === Environment.DEV) {
			return serverEnvironment
		}
	}
	return Environment.TEST
}

export function getAppServerRootURL(): string {
	return EnvConfigPwgo[getEnvironment()].appServerRootURL
}

export function getEnvUser(): UserCredentials {
	return EnvConfigPwgo[getEnvironment()].user
}

export function getPublicSiteURL(): string {
	return EnvConfigPwgo[getEnvironment()].publicSiteURL
}