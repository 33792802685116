import '../styles/Header.scss'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Shareholder } from 'typescript-fetch-api'
import { useState } from 'react'

import { navigateToHome, navigateToTile } from '../../home/actions'
import { navigateToContactUs } from '../actions'
import { Paths } from '../types'
import { RootStoreState } from '../../root'
import { logout } from '../../auth/actions'
import EnvBanner from '../../../common-ui/components/EnvBanner'
import { getEnvironment } from '../../../utils/envConfig'
import AccountsDrawer from '../../accounts/components/AccountsDrawer'

const AuthHeader = (): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()
	const location = useLocation()

	/**
	 * Store State
	 */

	const firstname = useSelector<RootStoreState, string | undefined>(state => state.accounts.firstname)
	const lastname = useSelector<RootStoreState, string | undefined>(state => state.accounts.lastname)
	const selectedAccount = useSelector<RootStoreState, Shareholder | undefined>(state => state.accounts.accounts.find(account => account.id === state.accounts.selectedAccountId))


	/**
	 * Local State
	 */

	const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)

	/**
	 * Local Functions
	 */

	const _navigateToHome = () => {
		dispatch(navigateToHome())
	}

	const _navigateToGradedCashStatements = () => {
		dispatch(navigateToTile({ path: Paths.GRADED_CASH_STATEMENTS }))
	}

	const _navigateToAnnualStatements = () => {
		dispatch(navigateToTile({ path: Paths.ANNUAL_STATEMENTS }))
	}

	const _navigateToContactUs = () => {
		dispatch(navigateToContactUs())
	}

	const _navigateToLogin = () => {
		dispatch(logout())
	}

	const _onShowDrawer = () => {
		setIsDrawerVisible(true)
	}

	const _onHideDrawer = () => {
		setIsDrawerVisible(false)
	}

	return (
		<div
			id="header"
			className="header max-container sticky"
		>
			<div className="header__content responsive-container">
				<div className="header__row">
					<span className="header__text--top-nav" onClick={_onShowDrawer}>
						<span className="header__text--top-nav__regular">Hello</span>
						<span className="header__text--top-nav__bold"> {firstname} {lastname} {selectedAccount ? `#${selectedAccount.allotmentNumber}` : ''} </span>
						<span className="header__text--top-nav__arrow">▶</span>
					</span>
					<div className="header__top_nav">
						<span className="header__text--top-nav" onClick={_navigateToContactUs}>Contact Us</span>
						<span className="header__text--top-nav" onClick={_navigateToLogin}>Sign Out</span>
					</div>
				</div>
				<div className="header__row">
					<span className="icon-mynzpm" onClick={_navigateToHome} />

					<EnvBanner environment={getEnvironment()} />

					<div className="header__bottom_nav">
						<p className={`header__text--tab ${location.pathname === Paths.HOME && 'header__text--tab__active'}`} onClick={_navigateToHome}>HOME</p>
						<p className={`header__text--tab ${location.pathname === Paths.GRADED_CASH_STATEMENTS && 'header__text--tab__active'}`} onClick={_navigateToGradedCashStatements}>GRADED CASH STATEMENTS</p>
						<p className={`header__text--tab ${location.pathname === Paths.ANNUAL_STATEMENTS && 'header__text--tab__active'}`} onClick={_navigateToAnnualStatements}>ANNUAL STATEMENTS</p>
					</div>
				</div>
			</div>
			<AccountsDrawer
				isDrawerVisible={isDrawerVisible}
				onHideDrawer={_onHideDrawer}
			/>
		</div>
	)
}

export default AuthHeader
