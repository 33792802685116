import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/**
 * Handles scrolling to the top on change of a new page/url.
 * @see [React Router - Scroll Restoration](https://reactrouter.com/web/guides/scroll-restoration)
 */
const ScrollToTop = (): null => {
	const { pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	return null
}

export default ScrollToTop