import '../styles/Header.scss'

import { useDispatch, useSelector } from 'react-redux'

import { navigateToHome } from '../../home/actions'
import { RootStoreState } from '../../root'
import AuthHeader from './AuthHeader'
import EnvBanner from '../../../common-ui/components/EnvBanner'
import { getEnvironment } from '../../../utils/envConfig'
import { navigateToContactUs } from '../actions'

const Header = (): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	 * Store State
	 */

	const isLoggedIn = useSelector<RootStoreState, boolean>(state => !!state.auth.token)
	const fetchingAccounts = useSelector<RootStoreState, boolean>(state => state.accounts.fetchingAccounts)

	/**
	 * Local Functions
	 */

	// after logging in, we load shareholder accounts to see if user has access to at least one shareholder
	if (isLoggedIn && !fetchingAccounts) {
		return <AuthHeader />
	}

	const _navigateToHome = () => {
		dispatch(navigateToHome())
	}

	const _navigateToContactUs = () => {
		dispatch(navigateToContactUs())
	}

	return (
		<div
			id="header"
			className="header max-container sticky"
		>
			<div className="header__content responsive-container">
				<div className="header__row">
					<span className="icon-mynzpm" onClick={_navigateToHome} />

					<EnvBanner environment={getEnvironment()} />

					<div className="header__top_nav">
						<span className="header__text--top-nav" onClick={_navigateToContactUs}>Contact Us</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Header
