import '../styles/Footer.scss'

import images from '../../../utils/imagesProvider'
import { IconLinks } from '../content'

const Footer = (): JSX.Element => {
	return (
		<div className="footer">
			<div className="footer__top--container max-container">
				<div className="footer__top--content responsive-container">
					{IconLinks.map(({ icon, link }) => (
						<a key={icon} href={link} target="_blank" rel="noreferrer" className={`${icon} footer__top--content__logo`} />
					))}
				</div>
			</div>

			<div className="footer__bottom--container max-container">
				<div className="footer__bottom--content responsive-container">
					<div className="footer__logo">
						<p>powered by</p>
						<img src={images.pwgo_footer_logo} className="logo" alt="Logo" />
					</div>

					<div className="footer__details">
						<p>myNZPM</p>
						<p>Copyright © 2023 NZPM Co-operative | V{process.env.REACT_APP_VERSION}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Footer
