import { SagaIterator } from 'redux-saga'
import { select, takeEvery } from 'redux-saga/effects'

import { firebase } from '../../App'
import * as actions from './actions'
import * as auth from '../auth/actions'
import * as accounts from '../accounts/actions'
import * as gradedCash from '../gradedcash/actions'
import * as annual from '../annual/actions'
import * as contactUs from '../contactus/actions'

import { isText, isValidEmail } from '../../utils/functions'
import { UserProperties } from './types'
import { Screens } from '../navigation/types'
import { authTokenSelector } from '../auth/selectors'
import { OAuthResponse } from 'typescript-fetch-api'

/**
 * ANALYTICS & SETUP
 */

function setupAnalyticsUser(action: accounts.FetchProfileSuccessAction) {
	const userId = action.payload.result.userId.toString()
	firebase.analytics.setUserProperties([{ [UserProperties.USER_ID]: userId }])
}

function resetAnalyticsUser() {
	firebase.analytics.setUserProperties([{ [UserProperties.USER_ID]: null }])
}

function logScreenView(action: actions.LogScreenViewAction) {
	firebase.analytics.logEvent('screen_view', {
		app_name: 'myNZPM',
		screen_name: action.payload.screen,
	})
}

/**
 * AUTH
 */

function handleLoginDone(action: auth.LoginRequestSuccessAction) {
	const username = action.payload.params.username
	if (!username) return

	let method: 'email' | 'mobile' | 'username'
	// checks for the method used by the user to login
	if (isText(username) === false) {
		method = 'mobile'
	} else if (isValidEmail(username)) {
		method = 'email'
	} else {
		method = 'username'
	}

	firebase.analytics.logEvent('login', {
		app_name: 'myNZPM',
		method,
	})
}

/**
 * GRADED CASH
 */

function handleDownloadGradedCashStatementSuccess() {
	firebase.analytics.logEvent('export', {
		app_name: 'myNZPM',
		screen: Screens.GRADED_CASH_STATEMENTS,
		method: 'download',
	})
}

/**
 * ANNUAL
 */

function handleDownloadAnnualStatementsSuccess() {
	firebase.analytics.logEvent('export', {
		app_name: 'myNZPM',
		screen: Screens.ANNUAL_STATEMENTS,
		method: 'download',
	})
}

function handleEmailAnnualStatementsSuccess() {
	firebase.analytics.logEvent('export', {
		app_name: 'myNZPM',
		screen: Screens.ANNUAL_STATEMENTS,
		method: 'email',
	})
}

/**
 * CONTACT US
 */

function* handleSendContactUsSuccess() {
	const authToken: OAuthResponse | undefined = yield select(authTokenSelector)
	firebase.analytics.logEvent('contact_us', {
		app_name: 'myNZPM',
		authenticated: !!authToken,
	})
}

export default function* (): SagaIterator {
	// analytics & setup
	yield takeEvery([auth.logout, auth.refreshAuthTokenFailed], resetAnalyticsUser)
	yield takeEvery(accounts.fetchProfile.done, setupAnalyticsUser)
	yield takeEvery(actions.logScreenView, logScreenView)

	// auth
	yield takeEvery(auth.login.done, handleLoginDone)

	// graded cash
	yield takeEvery(gradedCash.downloadGradedCashStatement.done, handleDownloadGradedCashStatementSuccess)

	// annual
	yield takeEvery([annual.downloadAnnualStatement.done, annual.downloadAnnualStatements.done], handleDownloadAnnualStatementsSuccess)
	yield takeEvery(annual.emailAnnualStatements.done, handleEmailAnnualStatementsSuccess)

	// contact us
	yield takeEvery(contactUs.sendContactUs.done, handleSendContactUsSuccess)
}