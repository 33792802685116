import * as Api from 'typescript-fetch-api'
import { getAppServerRootURL } from '../../utils/envConfig'
import { store } from '../root/index'

const API_KEY: string = 'x38V93VvtMtik8DXLYX3lhWmSbAOjKEv'
export const CLIENT_ID: string = 'plumbingworld-shareholding-client'
export const CLIENT_SECRET: string = '3FcAc964675f4385B03d9590206f3Bf2'

export const RECAPTCHA_SITE_KEY = '6Lf9ydkUAAAAAOGhOziWuljfe8Qcp2u2XE--M87F'

/** The API configuration. */
const configuration = new Api.Configuration({
    basePath: getAppServerRootURL(),
    apiKey: API_KEY,
    username: CLIENT_ID,
    password: CLIENT_SECRET,
    accessToken: ((name?: string, scopes?: string[]): string => {
        let accessToken = store.getState().auth.token
        if (accessToken && accessToken.access_token) {
            return 'Bearer ' + accessToken.access_token
        } else {
            // TODO the generated API doesn't support not returning a valid access token
            // We send a string, rather than nothing, so the server responds with a 401 rather
            // than a 403. A 401 signals that we need to authenticate, so the rest of our code
            // handles the failure appropriately. See handleDiscard.
            return 'INVALID'
        }
    }),
})

export function getOAuthApi(headers?: Api.HTTPHeaders): Api.OauthApi {
    const oAuthConfiguration = new Api.Configuration({
        basePath: getAppServerRootURL(),
        apiKey: API_KEY,
        username: CLIENT_ID,
        password: CLIENT_SECRET,
        headers,
    })
    return new Api.OauthApi(oAuthConfiguration)
}

export function getUserApi(): Api.UserApi {
    return new Api.UserApi(configuration)
}

export function getShareholdingApi(): Api.ShareholdingApi {
    return new Api.ShareholdingApi(configuration)
}

export function getRegisterApi(): Api.RegisterApi {
    return new Api.RegisterApi(configuration)
}
