import { call, put, takeEvery } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { REHYDRATE } from 'redux-persist'
import * as Api from 'typescript-fetch-api'

import { authenticate, callApi } from '../api/functions'
import {
	login, LoginRequestAction, LoginRequestParams, authStoreRehydrated, checkExchangeToken, CheckExchangeTokenAction, logout, forgotPassword, ForgotPasswordAction, ForgotPasswordPayload, changePassword, ChangePasswordRequestAction, ChangePasswordRequestPayload, RequestShareholdingAccessAction, requestShareholdingAccess, RequestShareholdingAccessPayload
} from './actions'
import { getAppServerRootURL } from '../../utils/envConfig'
import * as RootNavigation from '../navigation/NavigationManager'
import { getRegisterApi } from '../api'
import { getShareholdingApi } from '../api'

function* handleLogin(action: LoginRequestAction): SagaIterator {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		login,
		(payload: LoginRequestParams) => {
			return authenticate(payload)
				.catch(error => {
					let errorText = 'There was an error logging in'
					if (error instanceof Response) {
						switch (error.status) {
							case 400:
								errorText = 'Invalid credentials'
								break
							case 401:
								// staff member does not have branch group authority
								errorText = 'You do not have access to use this site. Please contact Service Desk (extension: 41099)'
								break
							case 403:
								errorText = 'Access denied'
								break
							case 423:
								errorText = 'Too many failed login attempts. Please contact Service Desk (extension: 41099)'
								break
						}
					}
					throw new Error(errorText)
				})
		},
		false
	)
}

// TODO: Check for specific payload type on redux-persist library
function* handleAuthStoreRehydrated(action: any): SagaIterator {
	// fetches the lists on rehydrate if we already have a saved token in our store
	if (action.key === 'auth' && action.payload && action.payload.token) {
		yield put(authStoreRehydrated())
	}
}

/**
 * Had to extract this code out into function to work nicely within a saga
 */
function getProfileOfImpersonatedUser(token: string): Promise<boolean> {
	// construct new UserApi so we can attempt to use the given access token before setting it on our redux store
	const config = new Api.Configuration({
		basePath: getAppServerRootURL(),
		accessToken: () => {
			return 'Bearer ' + token
		},
	})
	const userApi = new Api.UserApi(config)

	// attempt to get users profile to test if access token is valid
	return userApi.getAccounts()
		.then(_ => {
			// token valid
			return true
		}).catch(response => {
			if (response instanceof Response) {
				if (response.status === 401) {
					// invalid token
					throw Error('Invalid token')
				}
			}
			throw Error('An error occurred trying to use token')
		})
}

function* handleCheckExchangeToken(action: CheckExchangeTokenAction): SagaIterator {
	const { token } = action.payload

	try {
		const success = yield call(getProfileOfImpersonatedUser, token)
		if (success) {
			// we are changing to the impersonated user by setting access token as if we just logged in as that user
			yield put(login.done({ params: {}, result: { access_token: token } })) // TODO refresh token
			// navigate to home page (replace history)
			RootNavigation.navigateToHome(true)
		}
	} catch (e) {
		console.log('handleCheckExchangeToken e', e)
		// log user out if they are logged in as someone else
		yield put(logout())
		RootNavigation.navigateToHome(true)
	}
}

function* handleForgotPasswordStarted(action: ForgotPasswordAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		forgotPassword,
		(payload: ForgotPasswordPayload) => {
			return getRegisterApi().resetPassword({
				resetPasswordRequest: { user: payload.loginId },
			})
		})
}

function* handleChangePasswordStarted(action: ChangePasswordRequestAction): SagaIterator {
	// perform the set new password request
	yield call(
		// @ts-ignore API
		callApi,
		action,
		changePassword,
		(payload: ChangePasswordRequestPayload) => {
			return getRegisterApi()
				.resetNewPassword({
					confirmResetPassword: payload
				})
				.catch((error) => {
					let errorText = 'An unknown error has occurred'
					if (error instanceof Response) {
						switch (error.status) {
							case 401:
								errorText = 'Invalid username/password supplied'
								break
							case 403:
								errorText = 'Invalid verification code'
								break
							case 406:
								errorText = 'Invalid password'
								break
							default:
								// @ts-ignore
								if (error.error_description) {
									// @ts-ignore
									errorText = error.error_description
								}
						}
					}
					throw new Error(errorText)
				})
		})
}

function* handleRequestShareholdingAccess(action: RequestShareholdingAccessAction): SagaIterator {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		requestShareholdingAccess,
		(payload: RequestShareholdingAccessPayload) => {
			return getShareholdingApi().requestShareholdingAccess({ requestShareholdingAccessRequest: payload })
		}
	)
}

export default function* (): SagaIterator {
	yield takeEvery(login.started, handleLogin)
	yield takeEvery(REHYDRATE, handleAuthStoreRehydrated)

	yield takeEvery(checkExchangeToken, handleCheckExchangeToken)
	yield takeEvery(forgotPassword.started, handleForgotPasswordStarted)
	yield takeEvery(changePassword.started, handleChangePasswordStarted)
	yield takeEvery(requestShareholdingAccess.started, handleRequestShareholdingAccess)
}