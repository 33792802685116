import { AnnualStatement } from 'typescript-fetch-api'
import { actionCreatorFactory, Action, Success } from 'typescript-fsa'

const actionCreator = actionCreatorFactory('AnnualStatements')

export interface FetchAnnualStatementsPayload {
	//
}
export type FetchAnnualStatementsAction = Action<FetchAnnualStatementsPayload>
export type FetchAnnualStatementsSuccessAction = Action<Success<FetchAnnualStatementsPayload, AnnualStatement[]>>
export const fetchAnnualStatements = actionCreator.async<FetchAnnualStatementsPayload, AnnualStatement[], Error>('FETCH_ANNUAL_STATEMENTS')

export interface DownloadAnnualStatementPayload {
	shareholderNumber: number
	financialYear: number
}
export type DownloadAnnualStatementAction = Action<DownloadAnnualStatementPayload>
export type DownloadAnnualStatementSuccessAction = Action<Success<DownloadAnnualStatementPayload, Blob>>
export const downloadAnnualStatement = actionCreator.async<DownloadAnnualStatementPayload, Blob, Error>('DOWNLOAD_ANNUAL_STATEMENT')

export interface DownloadAnnualStatementsPayload {
	shareholderNumber: number
	statements: AnnualStatement[]
}
export type DownloadAnnualStatementsAction = Action<DownloadAnnualStatementsPayload>
export type DownloadAnnualStatementsSuccessAction = Action<Success<DownloadAnnualStatementsPayload, Blob>>
export const downloadAnnualStatements = actionCreator.async<DownloadAnnualStatementsPayload, Blob, Error>('DOWNLOAD_ANNUAL_STATEMENTS')

export interface EmailAnnualStatementsPayload {
	shareholderNumber: number
	email: string
	saveEmail?: boolean
	statements: AnnualStatement[]
}
export type EmailAnnualStatementsAction = Action<EmailAnnualStatementsPayload>
export const emailAnnualStatements = actionCreator.async<EmailAnnualStatementsPayload, void, Error>('EMAIL_ANNUAL_STATEMENTS')

export const clearDownloadError = actionCreator('CLEAR_DOWNLOAD_ERROR')
