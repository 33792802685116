import actionCreatorFactory, { Action } from 'typescript-fsa'

const actionCreator = actionCreatorFactory('HOME')

export type NavigateToTileAction = Action<NavigateToTileActionPayload>
export interface NavigateToTileActionPayload {
	path: string
}
export const navigateToTile = actionCreator<NavigateToTileActionPayload>('NAVIGATE_TO_TILE')

export const navigateToHome = actionCreator('NAVIGATE_TO_HOME')
