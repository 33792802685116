import './styles/app.scss'

import { Provider, useSelector } from 'react-redux'
import { Route, BrowserRouter, Navigate } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import 'firebase/analytics'

import { store, persistor, RootStoreState } from './modules/root'
import { Paths } from './modules/navigation/types'
import Routes from './modules/navigation/routes'
import LazySwitch from './modules/navigation/components/LazySwitch'
import { PersistGate } from 'redux-persist/integration/react'
import Header from './modules/navigation/components/Header'
import Footer from './modules/navigation/components/Footer'
import ScrollToTop from './modules/navigation/components/ScrollToTop'
import NavigateSetter from './modules/navigation/components/NavigateSetter'
import muiTheme from './styles/muiTheme'
import { RECAPTCHA_SITE_KEY } from './modules/api'
import FAB from './modules/navigation/components/FAB'
import { setupFirebase } from './utils/functions'

export const firebase = setupFirebase()

const App = (): JSX.Element => {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<BrowserRouter>
					<MuiThemeProvider theme={muiTheme}>
						<ScrollToTop />
						<NavigateSetter />
						<div className="app">
							<Header />
							<main id="main" className="responsive-container">
								<LazySwitch>
									<Route
										path={Paths.LOGIN}
										element={
											<PublicRoute>
												<Routes.Login />
											</PublicRoute>
										}
									/>
									<Route
										path={Paths.HOME}
										element={
											<PrivateRoute>
												<Routes.Home />
											</PrivateRoute>
										}
									/>
									<Route
										path={Paths.FORGOT_PASSWORD}
										element={
											<PublicRoute>
												<Routes.ForgotPassword />
											</PublicRoute>
										}
									/>
									<Route
										path={Paths.RESET_PASSWORD}
										element={
											<PublicRoute>
												<Routes.ResetPassword />
											</PublicRoute>
										}
									/>
									<Route
										path={Paths.ANNUAL_STATEMENTS}
										element={
											<PrivateRoute>
												<Routes.AnnualStatements />
											</PrivateRoute>
										}
									/>
									<Route
										path={Paths.GRADED_CASH_STATEMENTS}
										element={
											<PrivateRoute>
												<Routes.GradedCashStatements />
											</PrivateRoute>
										}
									/>
									<Route
										path={Paths.CONTACT_US}
										element={
											<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
												<Routes.ContactUs />
											</GoogleReCaptchaProvider>
										}
									/>
									<Route path={Paths.TOKEN} element={<Routes.TokenExchange />} />
									{/* default case */}
									<Route path="*" element={<Navigate to={Paths.LOGIN} />} />
								</LazySwitch>
							</main>
							<Footer />
							<FAB />
						</div>
					</MuiThemeProvider>
				</BrowserRouter>
			</PersistGate>
		</Provider>
	)
}

function PublicRoute({ children }: { children: React.ReactElement }) {
	const isLoggedIn = useSelector<RootStoreState, boolean>(state => !!state.auth.token)
	const hasShareholdingAccess = useSelector<RootStoreState, boolean>(state => state.accounts.accounts.length > 0)
	return !isLoggedIn ? children : !hasShareholdingAccess ? children : <Navigate to={Paths.HOME} />
}

function PrivateRoute({ children }: { children: React.ReactElement }) {
	const isLoggedIn = useSelector<RootStoreState, boolean>(state => !!state.auth.token)
	return isLoggedIn ? children : <Navigate to={Paths.LOGIN} />
}

export default App
