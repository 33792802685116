import { reducerWithInitialState } from 'typescript-fsa-reducers'

import { sendContactUs } from './actions'
import { logout } from '../auth/actions'

export interface StoreState {
	readonly loading: boolean
	readonly error?: Error
}

export const INITIAL_STATE: StoreState = {
	loading: false,
	error: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)
	// fetch statements
	.case(sendContactUs.started, (state): StoreState => ({
		...state, loading: true, error: undefined,
	}))
	.case(sendContactUs.done, (state): StoreState => ({
		...state, loading: false,
	}))
	.case(sendContactUs.failed, (state, payload): StoreState => ({
		...state, loading: false, error: payload.error,
	}))
	// clear state on logout
	.case(logout, (): StoreState => INITIAL_STATE)
