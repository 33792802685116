import { SagaIterator } from 'redux-saga'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { Shareholder } from 'typescript-fetch-api'

import * as actions from './actions'
import { callApi } from '../api/functions'
import { getShareholdingApi } from '../api'
import { accountSelector } from '../accounts/selectors'
import { downloadFile } from '../../utils/functions'

function* handleFetchGradedCashStatements(action: actions.FetchGradedCashStatementsAction): SagaIterator {
	// get current shareholder
	const shareholder: Shareholder | undefined = yield select(accountSelector)
	if (!shareholder) {
		yield put(actions.fetchGradedCashStatements.failed({ params: action.payload, error: new Error('No account selected') }))
	} else {
		yield call(
			// @ts-ignore callApi
			callApi,
			action,
			actions.fetchGradedCashStatements,
			(payload: actions.FetchGradedCashStatementsPayload) => {
				const shareholderNumber = Number(shareholder.allotmentNumber)
				return getShareholdingApi().getGradedCashStatements({ shareholderNumber })
			}
		)
	}
}

function* handleDownloadGradedCashStatement(action: actions.DownloadGradedCashStatementAction): SagaIterator {
	// get current shareholder
	const shareholder: Shareholder | undefined = yield select(accountSelector)
	if (!shareholder) {
		yield put(actions.downloadGradedCashStatement.failed({ params: action.payload, error: new Error('No account selected') }))
	} else {
		const shareholderNumber = Number(shareholder.allotmentNumber)
		yield call(
			// @ts-ignore callApi
			callApi,
			action,
			actions.downloadGradedCashStatement,
			(payload: actions.DownloadGradedCashStatementPayload) => {
				return getShareholdingApi()
					.downloadGradedCashStatement({ ...payload, shareholderNumber })
					.then((response: Blob) => {
						// create a download
						const filename = `${shareholderNumber} - ${payload.financialYear} Graded Cash Balance.xlsx`
						downloadFile(response, filename)
					})
			}
		)
	}
}

export default function* (): SagaIterator {
	yield takeLatest(actions.fetchGradedCashStatements.started, handleFetchGradedCashStatements)
	yield takeEvery(actions.downloadGradedCashStatement.started, handleDownloadGradedCashStatement)
}