import '../styles/AccountsDrawer.scss'

import { Accordion, AccordionDetails, AccordionSummary, Divider, Drawer } from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Shareholder } from 'typescript-fetch-api'

import { logout } from '../../auth/actions'
import { RootStoreState } from '../../root'
import { accountSelected } from '../actions'

interface Props {
	isDrawerVisible: boolean
	onHideDrawer: () => void
}

const AccountsDrawer = ({ isDrawerVisible, onHideDrawer }: Props): JSX.Element => {

	/**
	 * Dependency Hooks
	 */

	const dispatch = useDispatch()

	/**
	 * Store State
	 */

	const accounts = useSelector<RootStoreState, Shareholder[]>(state => state.accounts.accounts)
	const selectedAccountId = useSelector<RootStoreState, number | undefined>(state => state.accounts.selectedAccountId)

	/**
	 * Local State
	 */

	const [isSelectedAccountDetailsOpen, setIsSelectedAccountDetailsOpen] = useState<boolean>(false)

	/**
	 * Local Functions
	 */

	const _navigateToLogin = () => {
		setIsSelectedAccountDetailsOpen(false)
		dispatch(logout())
		onHideDrawer()
	}

	const _onAccountSelected = (accountId?: number) => {
		setIsSelectedAccountDetailsOpen(false)
		accountId && dispatch(accountSelected(accountId))
	}

	const _onHideDrawer = () => {
		setIsSelectedAccountDetailsOpen(false)
		onHideDrawer()
	}

	const _renderAccountRow = (account: Shareholder) => {
		const isSelected = account.id === selectedAccountId
		if (account.id === selectedAccountId) {
			return (
				// disabled expanding accordion until we have actual real data to display
				<Accordion expanded={isSelectedAccountDetailsOpen && false} onChange={() => setIsSelectedAccountDetailsOpen(!isSelectedAccountDetailsOpen)} key={account.id}>
					<AccordionSummary>
						<div className="accounts-drawer__account-details">
							<span className="accounts-drawer__text accounts-drawer__text--title">{account.name}</span>
							<span className="accounts-drawer__text">{`Allotment #${account.allotmentNumber}`}</span>
						</div>
						<Divider />
					</AccordionSummary>
					<AccordionDetails>
						{/* details */}
						<React.Fragment>
							<div className="accounts-drawer__details">
								<span className="accounts-drawer__details-text accounts-drawer__details-text--title">Details</span>
								<div className="accounts-drawer__details-row">
									<span className="accounts-drawer__details-text">Full Name</span>
									<span className="accounts-drawer__details-text accounts-drawer__details-text--subtitle">Anthony Young</span>
								</div>
								<div className="accounts-drawer__details-row">
									<span className="accounts-drawer__details-text">DOB</span>
									<span className="accounts-drawer__details-text accounts-drawer__details-text--subtitle">9 / 01 / 74</span>
								</div>
								<div className="accounts-drawer__details-row">
									<span className="accounts-drawer__details-text">Joining Date</span>
									<span className="accounts-drawer__details-text accounts-drawer__details-text--subtitle">30 / 11 / 99</span>
								</div>
								<div className="accounts-drawer__details-row">
									<span className="accounts-drawer__details-text">Contact</span>
									<span className="accounts-drawer__details-text accounts-drawer__details-text--subtitle">020434467392</span>
								</div>
							</div>
							<Divider className="accounts-drawer__divider-details" />
						</React.Fragment>

						{/* address */}
						<React.Fragment>
							<div className="accounts-drawer__details">
								<span className="accounts-drawer__details-text accounts-drawer__details-text--title">Address</span>
								<span className="accounts-drawer__details-text accounts-drawer__details-text--subtitle">22 Waterhouse Lane</span>
								<span className="accounts-drawer__details-text accounts-drawer__details-text--subtitle">Greenmount</span>
								<span className="accounts-drawer__details-text accounts-drawer__details-text--subtitle">Wellington</span>
								<span className="accounts-drawer__details-text accounts-drawer__details-text--subtitle">3042</span>

							</div>
							<Divider className="accounts-drawer__divider-details" />
						</React.Fragment>

						{/* current share holding */}
						<React.Fragment>
							<div className="accounts-drawer__details">
								<span className="accounts-drawer__details-text accounts-drawer__details-text--title">Current Share Holding</span>
							</div>
						</React.Fragment>

						{/* Ordinary Shares */}
						<React.Fragment>
							<div className="accounts-drawer__details-shares">
								<div className="accounts-drawer__total-row">
									<span className="accounts-drawer__details-text">Ordinary Shares</span>
									<span className="accounts-drawer__details-text accounts-drawer__details-text--shares-title">10,000</span>
								</div>
							</div>
							<Divider className="accounts-drawer__divider-total" />
						</React.Fragment>

						{/* Redeemable Preference Shares Floating */}
						<React.Fragment>
							<div className="accounts-drawer__details-shares">
								<div className="accounts-drawer__total-row">
									<span className="accounts-drawer__details-text">Redeemable Preference Shares Floating</span>
									<span className="accounts-drawer__details-text accounts-drawer__details-text--shares-title">5,241</span>
								</div>
							</div>
							<Divider />
						</React.Fragment>

						{/* Redeemable Preference Shares 24 Month */}
						<React.Fragment>
							<div className="accounts-drawer__details-shares">
								<div className="accounts-drawer__total-row">
									<span className="accounts-drawer__details-text">Redeemable Preference Shares 24 Month</span>
									<span className="accounts-drawer__details-text accounts-drawer__details-text--shares-title">326</span>
								</div>
							</div>
							<Divider />
						</React.Fragment>

						{/* Total Shares */}
						<React.Fragment>
							<div className="accounts-drawer__details-total">
								<div className="accounts-drawer__total-row">
									<span className="accounts-drawer__details-text accounts-drawer__details-text--total">Total Shares</span>
									<span className="accounts-drawer__details-text accounts-drawer__details-text--shares-title accounts-drawer__details-text--total">15,567</span>
								</div>
							</div>
						</React.Fragment>
					</AccordionDetails>
				</Accordion>
			)
		} else {
			return (
				<React.Fragment key={account.id}>
					<div
						className="accounts-drawer__account-row"
						onClick={() => _onAccountSelected(account.id)}
					>
						<div className="accounts-drawer__account-details">
							<span className="accounts-drawer__text accounts-drawer__text--title">{account.name}</span>
							<span className="accounts-drawer__text">{`Allotment #${account.allotmentNumber}`}</span>
						</div>
						{!isSelected &&
							<div className="accounts-drawer__switch-container">
								<span className="accounts-drawer__text">Switch</span>
								<span className="icon-arrow-right accounts-drawer__arrow-right" />
							</div>
						}
					</div>
					<Divider />
				</React.Fragment>
			)
		}
	}

	return (
		<Drawer
			open={isDrawerVisible}
			onClose={_onHideDrawer}
			anchor="right"
			className="accounts-drawer"
		>
			<div className="accounts-drawer__close--container">
				<span className="icon-close-round accounts-drawer__close--icon" onClick={onHideDrawer} />
			</div>

			<div className="accounts-drawer__content">
				<div className="accounts-drawer__header">
					<span className="accounts-drawer__header--text">Accounts</span>
				</div>

				<div className="accounts-drawer__container">
					{/* render accounts */}
					{accounts.map(account => (
						_renderAccountRow(account)
					))}

					{/* sign out */}
					<div className="accounts-drawer__account-row" onClick={_navigateToLogin}>
						<div className="accounts-drawer__account-details">
							<span className="accounts-drawer__text accounts-drawer__text--title">Sign out</span>
						</div>
						<span className="icon-arrow-right accounts-drawer__arrow-right" />
					</div>
				</div>
			</div>
		</Drawer>
	)
}

export default AccountsDrawer