/**
 * Defines the paths for all screens.
 */
export enum Paths {
	LOGIN = '/',
	HOME = '/home',
	FORGOT_PASSWORD = '/forgot-password',
	RESET_PASSWORD = '/reset-password',

	GRADED_CASH_STATEMENTS = '/graded-cash-statements',
	ANNUAL_STATEMENTS = '/annual-statements',
	CONTACT_US = '/contact-us',
	TOKEN = '/token', // for easy signin from public site
}

/**
 * Defines the screen names - mainly used for Analytics.
 */
export enum Screens {
	LOGIN = 'Login',
	HOME = 'Home',
	FORGOT_PASSWORD = 'Forgot Password',
	RESET_PASSWORD = 'Reset Password',

	GRADED_CASH_STATEMENTS = 'Graded Cash Statements',
	ANNUAL_STATEMENTS = 'Annual Statements',
	CONTACT_US = 'Contact Us',
	TOKEN = 'Token',
}

export interface Breadcrumb {
	title: string
	isClickable?: boolean
	type?: 'home' | 'annual' | 'gradedcash' | 'contactus'
}

export const HOME_BREADCRUMB: Breadcrumb = { title: 'Home', type: 'home', isClickable: true }

export type IconLink = {
	icon: string
	link: string
}

// user credentials to pass as params in the router state
export interface ForgotPasswordLocationState {
	mobileNumber: string
}
