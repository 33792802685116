import './styles.scss'

import React from 'react'

import { getEnvironmentIcon } from '../../utils/functions'

type Props = {
	environment: string
}

const EnvBanner = ({ environment }: Props): JSX.Element | null => {
	const banner = getEnvironmentIcon(environment)
	if (!banner) return null
	return <img src={banner} className="env-banner" alt="Environment" />
}

export default EnvBanner
