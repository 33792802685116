import { all } from 'redux-saga/effects'

import authSaga from '../auth/sagas'
import navigationSaga from '../navigation/sagas'
import accountsCashSaga from '../accounts/sagas'
import annualSaga from '../annual/sagas'
import gradedCashSaga from '../gradedcash/sagas'
import contactUsSaga from '../contactus/sagas'
import analyticsSaga from '../analytics/sagas'

export default function* rootSaga(): Generator {
	yield all([
		authSaga(),
		navigationSaga(),
		accountsCashSaga(),
		annualSaga(),
		gradedCashSaga(),
		contactUsSaga(),
		analyticsSaga(),
	])
}
